import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Shell from "./components/Shell";
import Footer from "./components/Footer";
import Payments from "./components/Payments";
import PaymentConfirm from "./components/PaymentConfirm";
import { Route, Routes } from "react-router-dom";
import WhroolPaymentConfirm from "./components/whrool/WhroolPaymentConfirm";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Shell>
              <Payments />
            </Shell>
          }
        />
        <Route
          path="/confirm"
          element={<PaymentConfirm />}
        />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
