import { XCircleIcon } from '@heroicons/react/solid';
import { DesignServices, Home, Info, LiveHelp, PhoneInTalk, WebAsset, Work, WorkOff } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import classNames from '../lib/classNames';
import Footer from './Footer'
// import { Dialog, DialogContent } from './ui/Dialog';


type ShellProps = {
    children?: React.ReactNode
}

export default function Shell(props: ShellProps) {

    const navigation = [
        {
            name: "HOME",
            link: "https://www.squbesoftsol.com/",
        },
        {
            name: "ABOUT US",
            link: "https://www.squbesoftsol.com/about.php",
        },
        {
            name: "SERVICES",
            link: "https://www.squbesoftsol.com/service.php",
        },
        {
            name: "PRODUCTS",
            link: "https://www.squbesoftsol.com/my-products.php",
        },
        {
            name: "PORTFOLIO",
            link: "https://www.squbesoftsol.com/portfolio.php",
        },
        {
            name: "FAQ's",
            link: "https://www.squbesoftsol.com/faq.php",
        },
        {
            name: "CONTACT US",
            link: "https://www.squbesoftsol.com/contact.php"
        }
    ]

    type navLinkStylesProps = {
        isActive: boolean,
    }
    const navLinkStyles = ({ isActive }: navLinkStylesProps) => {
        return {
            fontWeight: isActive ? "600" : "normal",
            borderBottom: isActive ? "4px solid #000046" : "none",
            paddingBottom: isActive ? "4px" : ""
        }
    }

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    // const [showForm, setShowForm] = useState(false);

    const onClose = () => {
        return (
            setShowMobileMenu(false)
        )
    }

    return (
        <>        
        <div className=' h-screen overflow-hidden'>

            {/* Stickey Header */}

            {/* Header */}
            <div className='bg-gradient-to-l from-clr2 to-clr1 '>
                <div className=' h-20 overflow-hidden py-2 flex items-center justify-between w-[90%] xl:w-[80%] mx-auto'>

                    {/* Logo */}
                    <div className=' h-full'><Link to="/" ><img src="/logo.png" alt="img" className=' h-full' /></Link></div>

                    {/* Top Nav Desktop Menu */}
                    <div className=' hidden lg:flex'>
                        {navigation.map((item, index) => {
                            return (
                                <div key={index}>
                                    {/* <NavLink style={navLinkStyles} to={item.link} className=" mx-3 text-white" >{item.name}</NavLink> */}
                                    <a href={item.link} className=" mx-3 text-white font-bold hover:text-[#41cbd5]">{item.name}</a>
                                </div>
                            )
                        })}
                    </div>

                    {/* Top Nav Mobile Menu */}
                    <div className='lg:hidden'>
                        <MobMenu />
                    </div>

                </div>
            </div>

            {/* Body */}
            <div className=' h-body-header overflow-y-auto'>
                {props.children}
            </div>
        </div>
        <Footer />
        </>
    )
}


function MobMenu() {
    const location = useLocation();
    const [showMobMenu, setShowMobMenu] = useState(false);
    // const { mutateAsync } = useLogoutMutation(client);
    // const handleLogout = async () => {
    //   //implement logout mutation @skpjr001
    //   try {
    //     const refreshToken = localStorage.getItem("refreshToken") as string;
    //     const {
    //       logout: { status, message },
    //     } = await mutateAsync({ input: { token: refreshToken } });
    //     localStorage.removeItem("accessToken");
    //     localStorage.removeItem("refreshToken");
    //     window.location.replace("/auth/logout");
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    const mobNavigation = [
        {
            name: "HOME",
            href: "/",
            icon: Home,
            current: location.pathname.startsWith("/"),
        },
        {
            name: "ABOUT US",
            href: "/about",
            icon: Info,
            current: location.pathname.startsWith("/about"),
        },
        {
            name: "SERVICES",
            href: "/services",
            icon: DesignServices,
            current: location.pathname.startsWith("/services"),
        },
        {
            name: "CARRIER",
            href: "/carrier",
            icon: Work,
            current: location.pathname.startsWith("/carrier"),
        },
        {
            name: "PORTFOLIO",
            href: "/portfolio",
            icon: WebAsset,
            current: location.pathname.startsWith("/portfolio"),
        },
        {
            name: "FAQ's",
            href: "/faqs",
            icon: LiveHelp,
            current: location.pathname.startsWith("/faqs"),
        },
        {
            name: "CONTACT US",
            href: "/contact",
            icon: PhoneInTalk,
            current: location.pathname.startsWith("/contact"),
        },
    ];
    return (
        <div className="flex items-center w-full space-x-2 cursor-pointer group">
            <MenuIcon
                className="w-8 h-auto text-white group-hover:text-gray-500"
                aria-hidden="true"
                onClick={(e) => setShowMobMenu(!showMobMenu)}
            />
            {/* Sidenav for Mobile Menu */}
            {showMobMenu && (
                <div className="absolute top-0 right-0 min-w-[180px] w-[40%] z-50">
                    <nav className="flex-1 h-screen overflow-y-auto px-2 space-y-1 bg-gradient-to-r from-clr1/90 to-clr2/90">
                        <div className="relative flex items-center justify-end top-2">
                            <XCircleIcon
                                className="w-10 h-10 text-white "
                                onClick={() => setShowMobMenu(!showMobMenu)}
                            />
                        </div>
                        {mobNavigation.map((item) => (
                            <div key={item.name}>
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        item.current
                                            ? "bg-brandColor-500 text-neutral-100 "
                                            : " text-white hover:text-neutral-100 hover:bg-brandColor-500",
                                        "group flex items-center px-3 sm:px-10 py-3 font-medium rounded-sm text-lg"
                                    )}
                                >
                                    <item.icon
                      className="flex-shrink-0 w-5 h-5 mr-2 text-white "
                      aria-hidden="true"
                    />
                                    <span className="inline">{item.name}</span>
                                </Link>
                                <div className=" w-full h-[1px]"></div>
                            </div>
                        ))}
                        {/* <button
                            onClick={() => handleLogout()}
                            className="flex items-center justify-center w-full px-3 py-3 text-lg font-medium text-white rounded-xl hover:text-neutral-100 hover:bg-brandColor-900 bg-brandColor-700"
                        >
                            {"Log Out"}
                        </button> */}
                    </nav>
                </div>
            )}
        </div>
    );
}
