import React from "react";
import { useNavigate } from "react-router-dom";
import Select, { StylesConfig } from "react-select";

export default function Payments() {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [subscriptionType, setSubscriptionType] = React.useState("");
  const [services, setServices] = React.useState("");
  const [amount, setAmount] = React.useState();

  //  colourStyles function is used for styling the React-Search inputs
  // const colourStyles: StylesConfig = {
  //     control: (styles) => ({
  //         ...styles,
  //         backgroundColor: "white",
  //         border: 0,
  //         boxShadow: "none",
  //         outline: "none",
  //         maxHeight: "70px",
  //         overflowX: "auto",
  //     }),
  //     input: (styles) => ({ ...styles }),
  //     placeholder: (styles) => ({ ...styles, color: "#000", }),
  //     singleValue: (styles) => ({ ...styles, color: "#000", }),
  //     menu: (styles) => ({ ...styles, width: "100%" }),
  //     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //         const color = "#00b4cf";
  //         return {
  //             ...styles,
  //             backgroundColor: isSelected
  //                 ? "#15678D"
  //                 : isFocused
  //                     ? "#15678D"
  //                     : "#00b4cf",
  //             color: isSelected ? "#ffffff" : isFocused ? "#ffffff" : "#ffffff",
  //             fontWeight: 700,
  //             cursor: "pointer",
  //             border: "none",
  //             ":active": {
  //                 ...styles[":active"],
  //                 backgroundColor: isSelected ? "#15678D" : "#00b4cf",
  //                 color: isSelected ? "#ffffff" : "#ffffff",
  //             },
  //         };
  //     },
  // };
  // End

  // const SubscriptionOptions = [
  //     { value: '1', label: '1 MONTH' },
  //     { value: '3', label: '3 MONTHS' },
  //     { value: '6', label: '6 MONTHS' },
  //     { value: '12', label: '12 MONTHS' }
  // ]

  return (
    <div className=" w-full h-full bg-[#4c81b5] flex items-center justify-center">
      <div className=" bg-white rounded-lg max-w-xl  w-96 sm:w-[50rem] min-h-[20rem] sm:min-h-[24rem] shadow-3xl px-4 mx-2 sm:px-8">
        <h1 className="my-4 text-2xl font-extrabold text-center text-clr2 sm:text-4xl">
          SUBSCRIPTION
        </h1>
        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1">
            NAME *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base"
            required
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            EMAIL *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            PHONE *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            value={phone}
            onChange={(e: any) => setPhone(e.target.value)}
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 grow">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1">
            <span>SERVICES</span>*
          </span>
          {/* <Select options={SubscriptionOptions} styles={colourStyles} className="w-full pl-[6px] text-xs text-left uppercase sm:text-lg" onChange={(e:any)=>setSubscriptionType(e.value)} /> */}
          <input
            id="services"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base"
            required
            value={services}
            onChange={(e: any) => setServices(e.target.value)}
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            AMOUNT *
          </span>
          <input
            id="txtEnrollmentNo"
            type={"number"}
            min={0}
            placeholder="Amount in Rupees /-"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            value={amount}
            onChange={(e: any) => setAmount(e.target.value)}
          />
        </div>

        <div className="flex items-center justify-center mt-6 gap-x-4">
          <button
            type="submit"
            className=" bg-[#00b4cf] px-4 py-2 text-xs sm:text-base font-bold text-white hover:bg-[#00b4cf]/70"
            onClick={() => navigate(-1)}
          >
            CANCEL
          </button>

          <button
            type="submit"
            className=" bg-[#00b4cf] px-4 py-2 text-xs sm:text-base font-bold text-white hover:bg-[#00b4cf]/70"
            onClick={() =>
              navigate(
                `/confirm?name=${name}&email=${email}&phone=${phone}&plan=${services}&amount=${amount}`
              )
            }
          >
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  );
}
