import axios from "axios";
const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export const fetcher_post = async ({
  url,
  payload,
}: {
  url: string;
  payload: any;
}) => {
  console.log(url, payload);
  return api.post(url, payload).then((res) => res.data);
};

export default api;
