import {
  BadgeCheckIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import api, { fetcher_post } from "../lib/axios";
import { Dialog, DialogContent } from "./ui/Dialog";
//Loading Razorpay Script
const loadRazorpay = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export default function SqubePaymentConfirm() {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [searchParams] = useSearchParams();
  console.log(Object.fromEntries([...searchParams]));

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    plan: "",
    amount: "",
    order_id: "",
  });

  useEffect(() => {
    setData({ ...data, ...Object.fromEntries([...searchParams]) });
  }, []);

  //  colourStyles function is used for styling the React-Search inputs
  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: 0,
      boxShadow: "none",
      outline: "none",
      maxHeight: "70px",
      overflowX: "auto",
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: "#000" }),
    singleValue: (styles) => ({ ...styles, color: "#000" }),
    menu: (styles) => ({ ...styles, width: "100%" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "#00b4cf";
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#15678D"
          : isFocused
          ? "#15678D"
          : "#00b4cf",
        color: isSelected ? "#ffffff" : isFocused ? "#ffffff" : "#ffffff",
        fontWeight: 700,
        cursor: "pointer",
        border: "none",
        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? "#15678D" : "#00b4cf",
          color: isSelected ? "#ffffff" : "#ffffff",
        },
      };
    },
  };
  // End

  const displayRazorpay = async () => {
    try {
      const url = "/api/v1/payment/create-sqube-order";
      const payload = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        plan: data.plan,
        amount: data.amount,
      };

      const { success, data: order } = await fetcher_post({ url, payload });

      console.log("sqube order", order);

      const res = await loadRazorpay(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) throw new Error("Razorpay SDk failed to load.❌");
      console.log(data);
      const options = {
        key: "rzp_live_egreeJfHj4RSa7",
        amount: order.amount,
        currency: order.currency,
        name: "Payment Gateway",
        description: `${data.plan} Plan for squbesoftsol.com`,
        image: "/logo.png",
        order_id: order.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (res: any) {
          const url = "/api/v1/payment/confirm-order-payment";
          const payload = {
            orderId: res.razorpay_order_id,
            paymentId: res.razorpay_payment_id,
            razorpaySignature: res.razorpay_signature,
          };
          const { success, data } = await fetcher_post({ url, payload });

          if (success) {
            //reloads the page
            // window.location.reload();
            setPaymentStatus("SUCCESS");
            setShowPopup(true);
            alert("Payment Successful.");
            setTimeout(() => {
              window.open("https://payments.squbesoftsol.com/", "_self");
            }, 8000);
          } else {
            setPaymentStatus("FAILED");
            setShowPopup(true);
            alert("Payment verification failed. Contact Support!💰");
            setTimeout(() => {
              window.open("https://payments.squbesoftsol.com/", "_self");
            }, 8000);
          }
          console.log(res);
        },
        prefill: {
          name: data?.name,
          email: data?.email,
          contact: data?.phone,
        },
        // "notes": {
        //     "address": "Whrool.com"
        // },
        theme: {
          color: "#0B4580",
        },
      };

      const _window = window as any;
      const payment = new _window.Razorpay(options);
      payment.open();
      payment.on("payment.failed", () => {
        alert("Payment Failed!");
      });
    } catch (err: any) {
      alert(err.message);
    }
  };

  return (
    <div className=" w-full h-full bg-[#4c81b5] flex items-center justify-center">
      <div className=" bg-white rounded-lg max-w-xl w-96 sm:w-[50rem] min-h-[20rem] sm:min-h-[24rem] shadow-3xl px-4 mx-2 sm:px-8">
        <h1 className="my-4 text-2xl font-extrabold text-center text-clr2 sm:text-4xl">
          CONFIRMATION
        </h1>
        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1">
            NAME *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base"
            required
            defaultValue={data?.name}
            disabled
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            EMAIL *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            defaultValue={data?.email}
            disabled
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            PHONE *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            defaultValue={data?.phone}
            disabled
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 grow">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1">
            <span>SERVICES</span>*
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            defaultValue={data?.plan}
            disabled
          />
        </div>

        <div className="relative flex mb-1 border border-gray-300 ">
          <span className=" bg-gray-400 px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left">
            AMOUNT (₹) *
          </span>
          <input
            id="txtEnrollmentNo"
            type="text"
            placeholder="Amount in Rupees /-"
            className="w-full py-1 pl-4 text-xs text-left uppercase sm:text-base "
            required
            defaultValue={data?.amount}
            disabled
          />
        </div>

        <div className="flex items-center justify-center mt-6 gap-x-4">
          <button
            type="submit"
            className=" bg-[#00b4cf] px-4 py-2 text-xs sm:text-base font-bold text-white hover:bg-[#00b4cf]/70"
            onClick={() => navigate(-1)}
          >
            GO BACK
          </button>

          <button
            type="button"
            onClick={() => displayRazorpay()}
            className=" bg-[#00b4cf] px-4 py-2 text-xs sm:text-base font-bold text-white hover:bg-[#00b4cf]/70"
          >
            PAY NOW
          </button>
        </div>
      </div>

      {showPopup && (
        <PaymentConfirmation
          status={paymentStatus}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export const PaymentConfirmation = ({
  status,
  onClose,
}: {
  status: string;
  onClose: any;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog open={true}>
      <DialogContent className=" max-w-xl min-h-[25rem] border border-none bg-[#0b3968] rounded-2xl">
        <div className=" w-full h-[22rem] rounded-3xl flex flex-col items-center justify-center">
          {status === "SUCCESS" ? (
            <BadgeCheckIcon className="w-40 h-40 text-white " />
          ) : (
            status === "FAILED" && (
              <ExclamationIcon className="w-40 h-40 text-white " />
            )
          )}
          <h1 className="text-4xl font-bold text-white ">
            {status === "SUCCESS"
              ? "PAYMENT IS SUCCESSFULL"
              : status === "FAILED"
              ? "PAYMENT FAILED"
              : status === "PENDING" && "PAYMENT IS PENDING"}
          </h1>
          <div className="flex my-4 gap-x-4">
            <button
              type="button"
              className=" text-white font-bold bg-[#13589e] px-4 py-2 rounded-xl "
              onClick={onClose}
            >
              CLOSE
            </button>
            <button
              type="button"
              className=" text-white font-bold bg-[#13589e] px-4 py-2 rounded-xl "
              onClick={onClose}
            >
              RETRY
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
