import React from 'react'

export default function HomeHeader() {

    return (
        <>
            <div className=' fixed top-0 left-0 w-full bg-[#963f54] flex  justify-center items-center h-20 sm:h-20 lg:h-24 2xl:h-32 z-50'>
                <a href="https://whrool.com"><img src="/logoGif.gif" alt="Logo Gif" className=" h-20 sm:h-20 2xl:h-28 p-2" /></a>
            </div>
        </>
    )
}



