import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Shell from './Shell';
import SqubePaymentConfirm from './SqubePaymentConfirm';
import WhroolPaymentConfirm from './whrool/WhroolPaymentConfirm';

export enum PaymentConfirmSetupStep {
  SQUBE,
  WHROOL,
}

const WithStep = ({
  step,
  current,
  children,
}: {
  step: PaymentConfirmSetupStep;
  current: PaymentConfirmSetupStep;
  children: JSX.Element;
}) => {
  return step === current ? children : null;
};


export default function PaymentConfirm() {
  const [searchParams] = useSearchParams();
  const [paymentType, setPaymentType] = useState(PaymentConfirmSetupStep.SQUBE);
	console.log(Object.fromEntries([...searchParams]));
  useLayoutEffect(()=>{
    if(Object.fromEntries([...searchParams])?.source==="OTHER"){
      setPaymentType(PaymentConfirmSetupStep.WHROOL)
    }
  },[])


  return (
    <div>
      <WithStep current={paymentType} step={PaymentConfirmSetupStep.SQUBE}>
        <Shell>
          <SqubePaymentConfirm />
        </Shell>
      </WithStep>

      <WithStep current={paymentType} step={PaymentConfirmSetupStep.WHROOL}>
        <WhroolPaymentConfirm />
      </WithStep>
    </div>
  );
}
