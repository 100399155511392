import {
  BadgeCheckIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import api, { fetcher_post } from "../../lib/axios";
import { Dialog, DialogContent } from "../ui/Dialog";
import HomeHeader from "./Header";
import axios from "axios";
//Loading Razorpay Script
const loadRazorpay = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export default function WhroolPaymentConfirm() {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [searchParams] = useSearchParams();
  console.log(Object.fromEntries([...searchParams]));

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    plan: "",
    amount: "",
    order_id: "",
  });

  useEffect(() => {
    setData({ ...data, ...Object.fromEntries([...searchParams]) });
  }, []);

  //  colourStyles function is used for styling the React-Search inputs
  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: 0,
      boxShadow: "none",
      outline: "none",
      maxHeight: "70px",
      overflowX: "auto",
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: "#000" }),
    singleValue: (styles) => ({ ...styles, color: "#000" }),
    menu: (styles) => ({ ...styles, width: "100%" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "#00b4cf";
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#15678D"
          : isFocused
          ? "#15678D"
          : "#00b4cf",
        color: isSelected ? "#ffffff" : isFocused ? "#ffffff" : "#ffffff",
        fontWeight: 700,
        cursor: "pointer",
        border: "none",
        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? "#15678D" : "#00b4cf",
          color: isSelected ? "#ffffff" : "#ffffff",
        },
      };
    },
  };
  // End

  const displayRazorpay = async () => {
    try {
      const res = await loadRazorpay(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) throw new Error("Razorpay SDk failed to load.❌");

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEYID,
        amount: data.amount,
        currency: "INR",
        name: "Whrool.com",
        description: `${data.plan} Plan for Whrool.com`,
        image: "https://whrool.com/images/logo.png",
        order_id: data.order_id,
        handler: async function (res: any) {
          try {
            const url = "/payment/verify";
            const payload = {
              orderId: res.razorpay_order_id,
              paymentId: res.razorpay_payment_id,
              razorpaySignature: res.razorpay_signature,
              planType: data.plan,
            };

            await axios.post(process.env.REACT_APP_API_URL! + url, payload);
            setPaymentStatus("SUCCESS");
            setShowPopup(true);
            alert("Payment Successful.");
          } catch (error) {
            setPaymentStatus("FAILED");
            setShowPopup(true);
            alert("Payment verification failed. Contact Support!💰");
          }
        },
        prefill: {
          name: data?.name,
          email: data?.email,
          contact: data?.phone,
        },

        theme: {
          color: "#0B4580",
        },
      };

      const _window = window as any;
      const payment = new _window.Razorpay(options);
      payment.open();
      payment.on("payment.failed", () => {
        alert("Payment Failed!");
      });
    } catch (err: any) {
      alert(err.message);
    }
  };

  return (
    <div className=' w-full h-screen bg-[#963f54]'>
      <HomeHeader />
      <div className='flex items-center justify-center w-full h-full '>
        <div className=' bg-white rounded-lg max-w-xl w-96 sm:w-[50rem] min-h-[20rem] sm:min-h-[24rem] shadow-3xl px-4 mx-2 sm:px-8 relative'>
          <XCircleIcon
            onClick={() =>
              window.open("https://whrool.com/subscription", "_self")
            }
            className=' absolute top-1 right-1 cursor-pointer w-8 h-8 text-[#963f54]'
          />
          <h1 className='my-4 text-2xl font-extrabold text-center text-[#963f54] sm:text-4xl'>
            CONFIRMATION
          </h1>
          <div className='relative flex mb-2 border border-gray-300 '>
            <span className=' bg-[#b67987] px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1'>
              NAME *
            </span>
            <input
              id='txtEnrollmentNo'
              type='text'
              className='w-full py-1 pl-4 text-xs text-left text-[#963f54] font-bold uppercase sm:text-base'
              required
              defaultValue={data?.name}
              disabled
            />
          </div>

          <div className='relative flex mb-2 border border-gray-300 '>
            <span className=' bg-[#b67987] px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left'>
              EMAIL *
            </span>
            <input
              id='txtEnrollmentNo'
              type='text'
              className='w-full py-1 pl-4 text-xs text-left text-[#963f54] font-bold uppercase sm:text-base '
              required
              defaultValue={data?.email}
              disabled
            />
          </div>

          <div className='relative flex mb-2 border border-gray-300 '>
            <span className=' bg-[#b67987] px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left'>
              PHONE *
            </span>
            <input
              id='txtEnrollmentNo'
              type='text'
              className='w-full py-1 pl-4 text-xs text-left text-[#963f54] font-bold uppercase sm:text-base '
              required
              defaultValue={data?.phone}
              disabled
            />
          </div>

          <div className='relative flex mb-2 border border-gray-300 grow'>
            <span className=' bg-[#b67987] px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left flex flex-col sm:flex-row gap-x-1'>
              <span>SERVICES</span>*
            </span>
            <input
              id='txtEnrollmentNo'
              type='text'
              className='w-full py-1 pl-4 text-xs text-left text-[#963f54] font-bold uppercase sm:text-base '
              required
              defaultValue={data?.plan}
              disabled
            />
          </div>

          <div className='relative flex mb-2 border border-gray-300 '>
            <span className=' bg-[#b67987] px-2 py-1 h-full text-xs sm:text-lg sm:whitespace-nowrap font-semibold min-w-[110px] sm:min-w-[250px] text-left'>
              AMOUNT (₹) *
            </span>
            <input
              id='txtEnrollmentNo'
              type='text'
              placeholder='Amount in Rupees /-'
              className='w-full py-1 pl-4 text-xs text-left text-[#963f54] font-bold uppercase sm:text-base '
              required
              defaultValue={data?.amount?.slice(0, -2)}
              disabled
            />
          </div>

          <div className='flex items-center justify-center mt-6 gap-x-4'>
            <button
              type='button'
              onClick={() => displayRazorpay()}
              className=' bg-[#963f54] rounded-xl px-4 py-2 text-xs sm:text-base font-bold text-white hover:bg-[#963f54]/70'>
              PAY NOW
            </button>
          </div>
        </div>

        {showPopup && (
          <PaymentConfirmation
            status={paymentStatus}
            onClose={() => setShowPopup(false)}
          />
        )}
      </div>
    </div>
  );
}

export const PaymentConfirmation = ({
  status,
  onClose,
}: {
  status: string;
  onClose: any;
}) => {
  const navigate = useNavigate();
  return (
    <Dialog open={true}>
      <DialogContent
        className=' max-w-xl min-h-[25rem] border bg-[#963f54] border-none rounded-2xl'
        style={{ background: "#963f54" }}>
        <div className=' w-full h-[22rem] rounded-3xl flex flex-col items-center justify-center gap-y-4'>
          {status === "SUCCESS" ? (
            <BadgeCheckIcon
              className='w-32 h-32 sm:w-40 sm:h-40 text-white '
              style={{ color: "#fff" }}
            />
          ) : (
            status === "FAILED" && (
              <ExclamationIcon
                className='w-32 h-32 sm:w-40 sm:h-40 text-white '
                style={{ color: "#fff" }}
              />
            )
          )}
          <h1
            className='text-2xl sm:text-4xl font-bold text-white text-center'
            style={{ color: "#fff" }}>
            {status === "SUCCESS"
              ? "PAYMENT IS SUCCESSFULL"
              : status === "FAILED"
              ? "PAYMENT FAILED"
              : status === "PENDING" && "PAYMENT IS PENDING"}
          </h1>
          <div className='flex items-center justify-center w-full my-4'>
            <button
              type='button'
              className=' w-[70%] mx-auto text-white font-bold bg-[#7f354c] hover:shadow-white px-4 py-2 rounded-xl '
              onClick={() =>
                window.open("https://whrool.com/subscription", "_self")
              }>
              CLOSE
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
