import { MailIcon } from '@heroicons/react/outline';
import { DeviceMobileIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { Copyright, Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import React, { useState } from 'react'
// import { RequirementForm } from './home/RequirementForm';
import Button from './ui/Button'

export default function Footer() {

    const [showForm, setShowForm] = useState(false);

    const onClose = () => {
        return(
            setShowForm(false)
        )
    }

    const linksData = [
        {
            name: "Web Development",
            link: "https://www.squbesoftsol.com/web-development.php",
        },

        {
            name: "Ecommerce Solutions",
            link: "https://www.squbesoftsol.com/ecommerce.php",
        },

        {
            name: "Mobile App Development",
            link: "https://www.squbesoftsol.com/application-development.php",
        },

        {
            name: "UI/UX Designing",
            link: "https://www.squbesoftsol.com/webdesign.php",
        },

        {
            name: "Digital Marketing",
            link: "https://www.squbesoftsol.com/dg-market.php",
        },

        {
            name: "Content Management System",
            link: "https://www.squbesoftsol.com/content-management.php",
        },
    ];

    const contactData = [
        {
            icon: LocationMarkerIcon,
            text: "6-21-19/1, 3rd Floor, CHINTA CITADEL 8/2 ARUNDALPET, GUNTUR, AP, India, Pin :522002",
        },

        {
            icon: DeviceMobileIcon,
            text: "+91 900 900 9648",
        },

        {
            icon: MailIcon,
            text: "info@squbesoftsol.com",
        },
    ];

    const socialMediaIcons = [
        {
            icon: Facebook,
        },
        {
            icon: Instagram,
        },

        {
            icon: LinkedIn,
        },

        {
            icon: Twitter,
        },
    ];
    return (
        <div className=' bg-clr4'>
            <div className=' relative w-[85%] mx-auto pt-14 pb-2'>
                {/* <div className=' absolute -top-14 left-6 sm:left-[28.5%] bg-[#8a7463] rounded-xl w-[85%] mx-auto  sm:w-1/2 h-[5.5rem] '></div> */}
                <div className=' relative'>
                    {/*Contact  Pop-up */}
                    {/* <div className=' border border-gray-300 absolute -top-24 left-10 sm:left-[30%] w-[85%] px-4 py-4 bg-clr3 rounded-xl flex flex-col lg:flex-row items-center justify-around sm:w-1/2 '>
                        <div>
                            <h1 className=' sm:text-xl md:text-2xl font-semibold whitespace-nowrap'>Ready for a Next Project ?</h1>
                            <p className='hidden lg:flex'>Let’s get Started!</p>
                        </div>
                        <div onClick={(e) => setShowForm(true)} className=' cursor-pointer bg-[#353333] px-4 py-2 font-semibold text-white rounded-xl'>
                            Contact Us
                        </div>
                    </div> */}
                    {/* {showForm && <RequirementForm  onClose={onClose}/>} */}


                    <div className=' grid sm:grid-rows-2 sm:grid-cols2  lg:grid-rows-1 lg:grid-cols-5 grid-row-1 gap-x-10 gap-y-10 text-white p-2'>
                        
                        {/* Le's Talk */}
                        <div className=' sm:col-span-2 lg:col-span-2 relative'>
                            <div><a href="https://www.squbesoftsol.com/"><img src="/logo.png" alt="img" className=' relative -left-2 my-2 w-40 h-14 cursor-pointer' /></a></div>
                            <div className=' flex flex-col items-start gap-y-4'>
                                <h1 className=' text-2xl'>Let's talk</h1>
                                <p>Every project starts with a chat. our Manager leads our client conversations and will be happ to discuss your project. He will also pull in the right people from the team when needed.</p>
                                
                                {/* <button onClick={(e) => setShowForm(true)} className=' cursor-pointer bg-clr3 rounded-xl shadow-2xl text-clr5 font-bold px-4 py-2'>Tell us about Your Project</button> */}
                            </div>
                        </div>
                        
                        {/* Our Links */}
                        <div className=' sm:col-span-1 lg:col-span-1 relative'>
                            <div>
                                <h1 className=' text-xl'>OUR LINKS</h1>
                                <div className=' w-[35%] h-[3px] bg-clr3 rounded-xl my-1'></div>
                            </div>
                            {linksData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Button className=" relative -left-3 cursor-pointer hover:text-base whitespace-nowrap"><a href={item.link}>{item.name}</a></Button>
                                    </div>
                                )
                            })}
                        </div>

                        {/* Contact Details */}
                        <div className=' sm:col-span-1 lg:col-span-2'>
                            <div>
                                <h1 className=' text-xl'>CONTACT</h1>
                                <div className=' w-[15%] h-[3px] bg-clr3 rounded-xl my-1'></div>
                            </div>
                            {contactData.map((item, index) => {
                                return (
                                    <div key={index} className=" flex items-center gap-x-3 my-4">
                                        {item.icon && <item.icon className=' w-8 h-8' />}
                                        <p>{item.text}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


                    <div className=' bg-clr3 w-full h-[2px] my-4'></div>

                    <div className=' flex flex-col sm:flex-row items-center justify-between w-full gap-y-4'>
                        <div className=' flex items-center text-white gap-x-1'>
                            <Copyright className=' w-8 h-8' />
                            <div>
                                2022
                                <strong className=' cursor-pointer text-clr2'> SQube Softsol Pvt Ltd.</strong>
                                &#xa0; All rights reserved.
                            </div>
                        </div>
                        <div className=' flex items-center'>
                            {socialMediaIcons.map((icon, index) => {
                                return (
                                    <div key={index} className=" mx-2">
                                        {icon.icon && <icon.icon className=' text-white footer-icon' />}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
